const WhiteLabelConfig = {
	mapping: [
        { companyCode: 'CN1688117055706',appCode: 'co.doctoratoz.puhealth',domainName: 'puhealth.doctoratoz.co',companyName: "กรมอนามัย",showHospital: false,showHospitalChangeAlway: false,showHomeOption: false },
        { companyCode: 'CN1691739003644',appCode: 'co.doctoratoz.bkkgi',domainName: 'healthtalkth.com',companyName: "BkkGi",showHospital: false,showHospitalChangeAlway: false,showHomeOption: false },
        { companyCode: 'CN1693232674837',appCode: 'co.doctoratoz.telepharmacy',domainName: 'telepharmacy.doctoratoz.co',companyName: "Telepharmacy",showHospital: false,showHospitalChangeAlway: false,showHomeOption: false },
        { companyCode: 'CN1643791013947',appCode: 'co.doctoratoz.demo',domainName: 'demo.doctoratoz.co',companyName: "Demo",showHospital: false,showHospitalChangeAlway: false,showHomeOption: false },
        { companyCode: 'CN1610705529244',appCode: 'co.doctoratoz.chularat',domainName: 'newchg.doctoratoz.co',companyName: "CHG Company",showHospital: true,showHospitalChangeAlway: true,showHomeOption: false },
        { companyCode: 'CN1658466861097',appCode: 'co.doctoratoz.wtcare',domainName: 'wt.doctoratoz.co',companyName: "วังทอง",showHospital: false,showHospitalChangeAlway: false,showHomeOption: false },
        { companyCode: 'CN1699870778015',appCode: 'co.doctoratoz.suphan',domainName: 'suphan.doctoratoz.co',companyName: "อบจ.สุพรรณบุรี",showHospital: false,showHospitalChangeAlway: false,showHomeOption: false },
        { companyCode: 'CN1621321597697',appCode: 'co.doctoratoz.chiva',domainName: 'chiva.doctoratoz.co',companyName: "CHIVA HISxHER",showHospital: false,showHospitalChangeAlway: false,showHomeOption: false },
        { companyCode: 'CN1600835175233',appCode: 'co.doctoratoz.mmt',domainName: 'newmmt.doctoratoz.co',companyName: "มิตรไมตรีคลินิก (mmt)",showHospital: false,showHospitalChangeAlway: false,showHomeOption: false },
        { companyCode: 'CN1660883798367',appCode: 'co.doctoratoz.chersery',domainName: 'ch.doctoratoz.co',companyName: "เฌ้อเซอรี่ โฮม",showHospital: false,showHospitalChangeAlway: false,showHomeOption: false },
        { companyCode: 'CN1737608071806',appCode: 'co.doctoratoz.hs',domainName: 'hs.doctoratoz.co',companyName: "Health Station",showHospital: false,showHospitalChangeAlway: false,showHomeOption: false },
        // CHG DEV
        { companyCode: 'CN1610705650771',appCode: 'co.doctoratoz.chularat',domainName: 'chg.doctoratoz.local',companyName: "CHG Company",showHospital: true,showHospitalChangeAlway: true,showHomeOption: false },
        { companyCode: 'CN1737607298055',appCode: 'co.doctoratoz.devhs',domainName: 'devhs.doctoratoz.co',companyName: "Dev Health Station",showHospital: false,showHospitalChangeAlway: true,showHomeOption: false }
    ]
};
export default WhiteLabelConfig;

import axios from 'axios'
import Bugsnag from '@bugsnag/js'
import _ from 'lodash'
import { IS_LOADING, HOSPITAL_LIST } from './types'
const setCache = false;
export const hospitalList = (data = [], nextKey = null) => async (dispatch) => {
  //need change loading
	const cacheData = localStorage.getItem('cacheHospitals');
	if(setCache && cacheData && JSON.parse(cacheData) && _.isArray(JSON.parse(cacheData)) && JSON.parse(cacheData).length > 0){
		dispatch({ type: HOSPITAL_LIST, playload: { dataAll: JSON.parse(cacheData) } });
	} else {
		let url = `${process.env.REACT_APP_API_URL}/v2/hospitals?limit=1000`; 
		const companyCode = localStorage.getItem('company-code');
		if(companyCode){
			url += `&refCompanyCode=${companyCode}`;
		}
		if (!_.isEmpty(nextKey)) {
			url += '&nextKey=' + nextKey;
		}
		let result = [];
		if (_.isArray(data) && data.length > 0) {
			result = data;
		}
		dispatch({ type: IS_LOADING, status: false, names: { HOSPITAL_LIST: false } });
		await axios.get(url).then(response => {
			result = result.concat(response.data.data);
			if (_.has(response.data, 'nextKey') && !_.isEmpty(response.data.nextKey)) {
				dispatch(hospitalList(result, response.data.nextKey));
			} else {
				if(_.isArray(result)){
					localStorage.setItem('cacheHospitals', JSON.stringify(result));
				}
				dispatch({ type: HOSPITAL_LIST, playload: { dataAll: result } });
				dispatch({ type: IS_LOADING, status: false, names: { HOSPITAL_LIST: false } });
			}
		}).catch(error => {
			dispatch({ type: IS_LOADING, status: false, names: { HOSPITAL_LIST: false } });
			if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
				if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
				Bugsnag.notify(error);
			}
		});
	}
}